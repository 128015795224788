<template>
    <div class="pt-body">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 mb-3">
                    <div class="row align-items-stretch">
                        <div class="col-3">
                            <div v-if="producto.adjuntos && producto.adjuntos.length>0">
                                <div style="height: 50px;" class="mb-3 text-center" v-if="producto.adjuntos[0]">
                                    <img :src="producto.adjuntos[0].ARCHIVO" alt="" class="img-fluid cursor-pointer h-100" v-on:click="image=producto.adjuntos[0].ARCHIVO">
                                </div>
                                <div style="height: 50px;" class="mb-3 text-center" v-if="producto.adjuntos[1]">
                                    <img :src="producto.adjuntos[1].ARCHIVO" alt="" class="img-fluid cursor-pointer h-100" v-on:click="image=producto.adjuntos[1].ARCHIVO">
                                </div>
                                <div style="height: 50px;" class="mb-3 text-center" v-if="producto.adjuntos[2]">
                                    <img :src="producto.adjuntos[2].ARCHIVO" alt="" class="img-fluid cursor-pointer h-100" v-on:click="image=producto.adjuntos[2].ARCHIVO">
                                </div>
                                <div style="height: 50px;" class="mb-3 text-center" v-if="producto.adjuntos[3]">
                                    <img :src="producto.adjuntos[3].ARCHIVO" alt="" class="img-fluid cursor-pointer h-100" v-on:click="image=producto.adjuntos[3].ARCHIVO">
                                </div>
                                <div style="height: 50px;" class="mb-3 text-center" v-if="producto.adjuntos[4]">
                                    <img :src="producto.adjuntos[4].ARCHIVO" alt="" class="img-fluid cursor-pointer h-100" v-on:click="image=producto.adjuntos[4].ARCHIVO">
                                </div>
                            </div>
                        </div>
                        <div class="col-9" v-if="image">
                            <img :src="image" alt="Foto del producto" class="img-fluid h-100">
                        </div>
                        <div class="col-lg-12" v-else>
                            <div class="d-flex align-items-center justify-content-center h-100 bg-light">
                                <font-awesome-icon :icon="faFileImage" style="height: 80px; width: 80px;" class="text-muted" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 mb-3 playfairDisplay">
                    <h1 v-text="producto.NOMBRE_PRODUCTO"></h1>
                    <h2 class="mb-3">
                        {{$filters.currency(producto.PRECIO)}}
                    </h2>
                    <p class="text-muted mb-4">
                        {{producto.DESCRIPCION}}
                    </p>
                    <div class="mb-4" v-if="!loadingMesas">
                        <form v-on:submit.prevent="addProduct" class="d-flex" v-if="boda.CODIGO_BODA == user.CODIGO_BODA">
                            <input type="number" class="form-control form-control-lg me-2" placeholder="Cantidad" aria-label="Cantidad" v-model="form.CANTIDAD" style="width: 100px;">
                            <button type="submit" class="btn btn-lg bg-light-secondary" id="button-addon2" required :disabled="loading">
                                <BtnLoading v-if="loading" text="Agregando..." />
                                <span v-else>
                                    <font-awesome-icon :icon="faGifts" /> Agregar a mi mesa
                                </span>
                            </button>
                        </form>
                        <div class="d-flex" v-else>
                            <input type="number" class="form-control form-control-lg me-2" placeholder="Cantidad" aria-label="Cantidad" v-model="form.CANTIDAD" style="width: 100px;">
                            <button type="button" class="w-100 btn rounded-0 py-3 bg-light-secondary" v-on:click.prevent.stop="addShop()" :disabled="loading" v-if="mesaDF.CANTIDAD != mesaDF.CANTIDAD_COMPRADA">
                                <BtnLoading v-if="loading" text="Añadiendo..." />
                                <span v-else>
                                    <font-awesome-icon :icon="faShoppingCart" /> Añadir al carrito
                                </span>
                            </button>
                        </div>
                    </div>

                    <ul class="list-unstyled">
                        <li v-if="producto.NOMBRE_PROVEEDOR"><strong>Proveedor:</strong> <span class="text-muted">{{producto.NOMBRE_PROVEEDOR}}</span></li>
                        <li v-if="producto.DESCRIPCION_MARCA"><strong>Marca:</strong> <span class="text-muted">{{producto.DESCRIPCION_MARCA}}</span></li>
                        <li v-if="producto.CODIGO_COMERCIAL"><strong>SKU:</strong> <span class="text-muted">{{producto.CODIGO_COMERCIAL}}</span></li>
                        <li v-if="producto.DESCRIPCION_GRUPO"><strong>Grupo:</strong> <span class="text-muted">{{producto.DESCRIPCION_GRUPO}}</span></li>
                        <li v-if="producto.DESCRIPCION_TIPO"><strong>Tipo:</strong> <span class="text-muted">{{producto.DESCRIPCION_TIPO}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BtnLoading from '@/components/BtnLoading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGifts, faFileImage, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
export default {
    components: {
        BtnLoading,
        FontAwesomeIcon
    },
    data(){
        return {
            loadingProducto: false,
            faGifts,
            faShoppingCart,
            faFileImage,
            form:{
                CANTIDAD: 1
            },
            loading: false,
            image: '',
            loadingMesas: false,
            mesaDF: {}
        }
    },
    mounted(){
        this.$store.commit('navStatic', 'bg-white')
        if(!this.producto.CODIGO_PRODUCTO){
            this.getData()
        }
        this.getBoda()
    },
    onmounted(){
        this.$store.commit('navStatic', 'bg-white')
    },
    methods:{
        /*addShop(){
            this.loading = true
            var data = this.mesa || {}
            data.CANTIDAD_ITEMS = this.form.CANTIDAD
            //data.CODIGO_BODA = this.boda
            if(data.CODIGO_BODA){
                this.$store.commit('addItem', data)
                this.$toast.success('El producto ha sido agregado')
            }else{
                this.$toast.error('No se ha seleccionado la boda')
            }
            this.loading = false
        },*/

        getData(){
            if(!this.codigo) {return}
            this.loadingProducto = true
            this.$store.dispatch('getProductos', {
                SLUG: this.codigo
            }).then((res) => {
                return res
            }).catch(err => {
                this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingProducto = false
            })
        },
        addProduct(){
            this.loading = true
            this.$store.dispatch('addMesas', {
                CODIGO_PRODUCTO: this.producto.CODIGO_PRODUCTO,
                CANTIDAD: this.form.CANTIDAD
            }).then(res => {
                this.$toast.success('El producto ha sido agregado')
                if(this.slug){
                    this.$router.push({name: 'boda.index'})
                }else{
                    this.$router.push({name: 'productos.index'})
                }
                return res
            }).catch(err => {
                if(err.response){
                    this.$toast.error('Error al agregar producto')
                }
            }).then(() => {
                this.loading = false
            })
        },

        getBoda(){
            if(!this.slug) {return}
            this.loadingMesas = true
            this.$store.dispatch('getBodas', {
                SLUG: this.slug
            }).then((res) => {
                return res
            }).catch(err => {
                this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingMesas = false
            })
        },
    },
    computed: {
        codigo(){
            return this.$route.params.id || null
        },

        slug(){
            return this.$route.params.slug || null
        },

        productos() {
            var data = this.$store.getters.productos || {}
            return data.data || []
        },

        producto() {
            return this.productos.filter(x => x.SLUG == this.codigo)[0] || {}
        },

        bodas() {
            var data = this.$store.getters.bodas || {}
            return data.data || []
        },

        boda() {
            return this.bodas.filter(x => x.SLUG == this.slug)[0] || {}
        },

        user(){
            return this.$store.getters.currentUser || {}
        },
    },
    watch: {
        'producto':{
            handler(val){
                if (val.adjuntos && val.adjuntos.length>0) {
                    this.image = val.adjuntos[0].ARCHIVO
                }
                return val
            },
            immediate: true
        }
    }
}
</script>